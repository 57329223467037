import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import moment from "moment";

const BirthdateInput = ({ props }) => {
  const { employee, setEmployee, dynamicSize } = props;
  return (
    <FieldContainer>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          slotProps={{ textField: { size: dynamicSize } }}
          label="Geburtsdatum"
          value={employee.birthdate ? moment(employee.birthdate) : null}
          onChange={(e) => {
            e
              ? setEmployee({ ...employee, birthdate: e.toDate() })
              : setEmployee({ ...employee });
          }}
        />
      </LocalizationProvider>
    </FieldContainer>
  );
};

export default BirthdateInput;
