import { Divider } from "@mui/material";
import styled from "@emotion/styled";

export const AlertContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-right: 10px;
`;

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

export const DividerAction = styled(Divider)`
  margin-left: 10px;
  height: 35px;
  margin-top: 5px;
`;
