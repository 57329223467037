import { Container, DateText, DropdownContainer, InfoText } from "./styles";
import React, { useEffect, useState } from "react";

import ChartContainer from "./ChartContainer";
import Dropdown from "./Dropdown";
import { Flex } from "@react-spectrum/layout";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";

const DiversityOverview = ({ dimensions }) => {
  const [teamName, setTeamName] = useState("Alle");
  const [diversityOverview, setDiversityOverview] = useState([]);
  const [teamOptions, setTeamOptions] = useState([{ name: "Alle", data: [] }]);
  const [loading, setLoading] = useState(true);

  const { getDiversityOverview } = useLightsApiPrivate();
  useEffect(() => {
    getDiversityOverview().then((data) => {
      const teamOptions = data.map((item) => item.team);
      setDiversityOverview(data);
      setTeamOptions(teamOptions);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedTeam = diversityOverview.find((team) => team.team === teamName);

  const teamWithMostDataEntries = selectedTeam?.data.reduce((max, current) => {
    return current.data.length > max?.data.length ? current : max;
  }, selectedTeam.data[0]);

  const hasEnoughEmployees = selectedTeam?.hasEnoughEmployees;

  return (
    <Container>
      <Flex width="94%" justifyContent="space-between">
        <DropdownContainer>
          <Dropdown
            dimension={teamName}
            setDimension={setTeamName}
            options={teamOptions}
          />
        </DropdownContainer>
        <DateText>Stand 1. August 2024</DateText>
      </Flex>

      {hasEnoughEmployees ? (
        <ChartContainer
          dimensions={dimensions}
          loading={loading}
          team={selectedTeam}
          largestTeamLength={teamWithMostDataEntries?.data.length}
        />
      ) : (
        <InfoText>
          Ein Team muss mindestens 10 Mitglieder mit einem erstelltem Profil
          haben, damit ein Überblick angezeigt werden kann.
        </InfoText>
      )}
    </Container>
  );
};

export default React.memo(DiversityOverview);
