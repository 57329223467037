import NumberButton from "./NumberButton";

export const RatingButtons = ({ rating, updateRating }) => {
  return Array.from({ length: 10 }, (_, k) => {
    const active = `${rating}` === `${k + 1}`;

    return (
      <NumberButton
        key={rating + k}
        rating={++k}
        active={active}
        onClick={(num) => updateRating(num)}
      />
    );
  });
};
