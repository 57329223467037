import * as Sentry from "@sentry/react";

import {
  ButtonContainer,
  FormContainer,
  InputContainer,
  LoginContainer,
  MailLink,
  StyledButton,
  SubHeader,
} from "./styles";
import React, { memo, useEffect, useRef, useState } from "react";

import Heading1 from "../../common/Heading1";
import LoginPageContainer from "../LoginPageContainer";
import { TextField } from "@mui/material";
import { Tooltip } from "@mui/material";
import { resetPassword } from "../../../api/lightsApi";
import { useNavigate } from "react-router-dom";
import usePortalSize from "../../../hooks/usePortalSize";

const ForgotPasswordPage = () => {
  const size = usePortalSize();

  const mailRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [mail, setMail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    mailRef.current && mailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [mail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // TODO: einheitliche Error Nachrichten
    try {
      await resetPassword({ mail });
      setMail("");
      setShowConfirmation(true);
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        setErrMsg("Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        setErrMsg("Bitte E-Mail eingeben.");
      } else if (err.response?.status === 404) {
        setErrMsg("Es ist kein User mit dieser E-Mail registriert.");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <LoginPageContainer>
      <LoginContainer className={size}>
        <FormContainer className={size}>
          {showConfirmation ? (
            <>
              <Heading1>Wir haben Dir eine E-Mail zugeschickt.</Heading1>
              <SubHeader>
                Bitte folge den Anweisungen in der E-Mail, um ein neues Passwort
                zu erhalten.
              </SubHeader>
              <StyledButton
                onClick={() => navigate(`/Login`)}
                variant="contained"
              >
                Zum Login
              </StyledButton>
            </>
          ) : (
            <>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <Heading1>Dein Passwort zurücksetzen.</Heading1>
              <SubHeader>Gib Deine E-Mail unten ein.</SubHeader>
              <form onSubmit={handleSubmit}>
                <InputContainer>
                  <TextField
                    label="E-Mail"
                    type="text"
                    id="mail"
                    size="small"
                    autoComplete="false"
                    onChange={({ target }) => setMail(target.value)}
                    value={mail}
                    required
                    InputLabelProps={{ required: false }}
                  ></TextField>
                </InputContainer>
                <ButtonContainer>
                  <StyledButton variant="contained" type="submit">
                    Passwort zurücksetzen
                  </StyledButton>
                </ButtonContainer>
                <Tooltip
                  title={
                    <span>
                      Bitte kontaktiere{" "}
                      <u>
                        <MailLink href="mailto:support@diverlyze.com">
                          support@diverlyze.com{" "}
                        </MailLink>{" "}
                      </u>
                    </span>
                  }
                  arrow
                  placement="bottom-start"
                >
                  <span>Du hast keine E-Mail?</span>
                </Tooltip>
              </form>
            </>
          )}
        </FormContainer>
      </LoginContainer>
    </LoginPageContainer>
  );
};

export default memo(ForgotPasswordPage);
