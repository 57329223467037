import * as Sentry from "@sentry/react";

import {
  ButtonContainer,
  FormContainer,
  InputContainer,
  LoginContainer,
  StyledButton,
  SubHeader,
} from "./styles";
import React, { memo, useEffect, useRef, useState } from "react";

import Heading1 from "../../common/Heading1";
import LoginPageContainer from "../LoginPageContainer";
import NewPasswordConfirmation from "../NewPasswordConfirmation";
import TextField from "@mui/material/TextField";
import { createPassword } from "../../../api/lightsApi";
import { useNavigate } from "react-router-dom";
import usePortalSize from "../../../hooks/usePortalSize";
import { useQuery } from "../../../hooks/useQuery";
import { validateNewPwCreds } from "../formValidation";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const size = usePortalSize();

  const mailRef = useRef();
  const errRef = useRef();

  const [userName, setUsername] = useState("");
  const [recPwd, setRecPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confPwd, setConfPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const query = useQuery();
  const paramUserName = query.get("userName");
  const paramPassCode = query.get("email_code");

  useEffect(() => {
    mailRef.current && mailRef.current.focus();
    setUsername(paramUserName);
    setRecPwd(paramPassCode);
  }, [paramUserName, paramPassCode]);

  useEffect(() => {
    setErrMsg("");
  }, [userName, recPwd, newPwd, confPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPwd !== confPwd) {
      setErrMsg("Passwörter stimmen nicht überein");
      return;
    }
    const { error } = validateNewPwCreds({
      userName,
      recoveryPassword: recPwd,
      newPassword: newPwd,
      confirmPassword: confPwd,
    });
    if (error) {
      Sentry.captureException(error);
      setErrMsg(error.message);
      return;
    }

    try {
      await createPassword({
        userName,
        mailCode: recPwd,
        newPassword: newPwd,
      });

      setUsername("");
      setNewPwd("");
      setConfPwd("");
      setRecPwd("");
      setShowConfirmation(true);
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Der User hat kein Passwort Reset angefordert");
      } else if (err.response?.status === 401) {
        setErrMsg("Der Mail Code ist nicht valide");
      } else if (err.response?.status === 404) {
        setErrMsg("Es ist kein User mit dieser Mail registriert.");
      } else if (err.response?.status === 409) {
        navigate("/password-already-created");
      } else {
        setErrMsg("Anforderung fehlgeschlagen");
      }
      errRef.current.focus();
    }
  };

  return (
    <LoginPageContainer>
      <LoginContainer className={size}>
        <FormContainer className={size}>
          {showConfirmation ? (
            <NewPasswordConfirmation />
          ) : (
            <>
              <Heading1>Erstelle ein neues Passwort.</Heading1>
              <SubHeader>Bitte gib Deine Daten ein.</SubHeader>
              <form onSubmit={handleSubmit}>
                <InputContainer>
                  <TextField
                    size="small"
                    label="E-Mail | Benutzername"
                    type="text"
                    id="mail"
                    autoComplete="false"
                    onChange={({ target }) => setUsername(target.value)}
                    value={userName}
                    InputLabelProps={{ required: false }}
                    required
                  ></TextField>
                  <TextField
                    size="small"
                    InputLabelProps={{ required: false }}
                    label="E-Mail Code"
                    type="text"
                    id="recoveryPassword"
                    onChange={({ target }) => setRecPwd(target.value)}
                    value={recPwd}
                    required
                  ></TextField>
                  <TextField
                    size="small"
                    InputLabelProps={{ required: false }}
                    label="Neues Passwort"
                    type="password"
                    id="newPassword"
                    onChange={({ target }) => setNewPwd(target.value)}
                    value={newPwd}
                    required
                  ></TextField>
                  <TextField
                    size="small"
                    InputLabelProps={{ required: false }}
                    label="Neues Passwort bestätigen"
                    type="password"
                    id="confirmationPassword"
                    onChange={({ target }) => setConfPwd(target.value)}
                    value={confPwd}
                    required
                  ></TextField>
                </InputContainer>
                <ButtonContainer>
                  <StyledButton variant="contained" type="submit">
                    Passwort bestätigen
                  </StyledButton>
                </ButtonContainer>
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
              </form>
            </>
          )}
        </FormContainer>
      </LoginContainer>
    </LoginPageContainer>
  );
};

export default memo(NewPasswordPage);
