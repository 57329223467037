import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import styled from "@emotion/styled";

export const CustomButton = styled(Button)`
  background-color: white;
  color: black;
  font-family: Rubik;
  width: 140px;
  font-size: 14px;
  height: 100px;
  min-height: 47px;
`;

export const IconButton = styled(Button)`
  background-color: white;
  color: black;
  font-family: Rubik;
  height: 100px;
  width: 40px;
  min-height: 47px;
`;

export const GrowStyled = styled(Grow)(({ placement }) => ({
  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
}));
