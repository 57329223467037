import { Button, TextField } from "@mui/material";
import {
  CompanyQuestionText,
  Container,
  QuestionText,
  RatingContainer,
  SkipText,
  TextFieldContainer,
  UpperContainer,
} from "./styles";
import React, { useState } from "react";

import { Flex } from "@react-spectrum/layout";
import Labels from "../../../PulseCheckPage/PulseCheck/PulseQuestion/Labels";
import { PULSEVIEWS } from "../../../PulseCheckPage/PulseCheck/pulseViews";
import { RatingButtons } from "../../../common/RatingButtons";
import Text from "../../../common/Text";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";
import usePortalSize from "../../../../hooks/usePortalSize";

const MailPulseQuestion = ({
  question,
  askedQuestionId,
  setView,
  setUnansweredAmount,
  unansweredAmount,
}) => {
  const [selectedRating, setSelectedRating] = useState(null);
  const [comment, setComment] = useState("");

  const alert = useAlert();
  const windowSize = usePortalSize();
  const { createAnswer } = useLightsApiPrivate();

  const submitAnswer = async () => {
    try {
      const answer = {
        askedQuestionId: askedQuestionId,
        rating: selectedRating,
      };
      if (comment !== "") {
        answer.commentary = { text: comment, read: false };
      }
      await createAnswer(answer);
      setUnansweredAmount(unansweredAmount - 1);

      setView(PULSEVIEWS.thanks);
    } catch (err) {
      alert.error("Es ist ein Problem aufgetreten.");
    }
  };

  return (
    <Container>
      <UpperContainer
        direction="column"
        justifyContent="space-between"
        width="10px"
        className={windowSize}
      >
        {!question.isCustom ? (
          <Text>
            Hilf uns, die Diversität, Gleichstellung und Inklusion in Deinem
            Unternehmen zu verbessern, indem Du uns Dein Feedback gibst.
          </Text>
        ) : (
          <Text>
            <CompanyQuestionText>
              Die folgende Frage wurde von Deinem Unternehmen erstellt.
            </CompanyQuestionText>
            Hilf uns, die Diversität, Gleichstellung und Inklusion bei euch zu
            verbessern, indem Du Dein Feedback gibst.
          </Text>
        )}
        <QuestionText>{question.contentDiverse}</QuestionText>
        <RatingContainer className={windowSize}>
          <Labels />
          <Flex justifyContent="space-between">
            <RatingButtons
              rating={selectedRating}
              updateRating={setSelectedRating}
            />
          </Flex>
          <SkipText onClick={() => setView(PULSEVIEWS.skip)}>
            Ich kann diese Frage nicht beantworten.
          </SkipText>
        </RatingContainer>
      </UpperContainer>
      <TextFieldContainer className={windowSize}>
        <TextField
          value={comment || ""}
          id="outlined-multiline-flexible"
          label="Dein Kommentar zur Frage"
          multiline
          maxRows={2}
          fullWidth={true}
          onChange={(e) => setComment(e.target.value)}
        />
      </TextFieldContainer>
      <Button
        variant="confirm"
        disabled={!selectedRating}
        onClick={submitAnswer}
      >
        Absenden
      </Button>
    </Container>
  );
};

export default MailPulseQuestion;
