import { Divider, TableCell } from "@mui/material";

import colors from "../../../common/colors";
import { computeContrastRatingColor } from "../../../../styles/computeScoreColors";
import styled from "@emotion/styled";

export const StyledScoreTableCell = styled(TableCell)`
  font-size: 16px;
  text-align: center;
  color: ${colors.midnight};
  background-color: ${({ rating }) => computeContrastRatingColor(rating)};
  font-weight: ${({ bold }) => (bold ? "500" : "normal")};
  font-family: Rubik;
`;

export const EmptyTableCell = styled(TableCell)`
  font-size: 16px;
  text-align: center;
  color: ${colors.midnight};
  background-color: ${colors.lightGrey};
  font-weight: ${({ bold }) => (bold ? "500" : "normal")};
`;

export const StyledAnswersLeftTableCell = styled(TableCell)`
  font-size: 16px;
  text-align: center;
  background-color: ${colors.lightGrey};
  color: ${colors.darkGrey};
`;

export const StyledDivider = styled(Divider)`
  align-self: center;
  transform: rotate(-45deg);
  width: 45px;
  margin: auto;
`;
