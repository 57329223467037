import { LoadingContainer, ModalButtonContainer } from "./styles";

import { Button } from "@mui/material";
import Heading2 from "../../../../../common/Heading2";
import LoadingAnimation from "../../../../../common/LoadingAnimation";
import Modal from "react-modal";
import React from "react";
import { modalStyle } from "../../../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../../hooks/useLightsApiPrivate";

const InvitationModal = ({
  amountNewEmps,
  isModalOpen,
  closeModal,
  setNewEmps,
  employees,
  setEmployees,
}) => {
  const [inviteLoading, setInviteLoading] = React.useState(false);

  const { postEmployeesInvite } = useLightsApiPrivate();
  const alert = useAlert();

  const inviteNewEmployees = async () => {
    setInviteLoading(true);
    try {
      postEmployeesInvite();
      setNewEmps([]);
      alert.success("Einladungen versendet");
      setInviteLoading(false);
    } catch (e) {
      alert.error("Es ist ein Fehler aufgetreten");
    }
  };

  const confirmModal = async () => {
    inviteNewEmployees();

    const employeesAllInvited = employees.map((emp) => {
      if (emp.status === "created" && emp.mail) {
        emp.status = "invited";
      }
      return emp;
    });
    setEmployees(employeesAllInvited);

    closeModal();
  };

  const displayedText =
    amountNewEmps > 1
      ? `${amountNewEmps} neu erstellte Mitglieder`
      : `Ein neu erstelltes Mitglied`;

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={modalStyle}
      contentLabel="Invitation"
    >
      {inviteLoading && (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      )}
      <Heading2>Einladungen versenden</Heading2>
      <p>{displayedText} per E-Mail einladen?</p>
      <ModalButtonContainer>
        <Button onClick={closeModal} variant="outlined">
          Abbrechen
        </Button>
        <Button onClick={confirmModal} variant="confirm">
          Einladen
        </Button>
      </ModalButtonContainer>
    </Modal>
  );
};

export default InvitationModal;
