export const pronounOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "he_him", label: "Er/Ihn" },
  { id: "she_her", label: "Sie/Ihr" },
  { id: "they_them", label: "Sie/Ihnen" },
  { id: "xe_xem", label: "Xe/Xem" },
  { id: "ze_hir", label: "Ze/Hir" },
  { id: "other_pronouns", label: "Andere" },
  { id: "he_they", label: "Er/Sie" },
  { id: "she_they", label: "Sie/Sie" },
  { id: "he_her_they", label: "Er/Sie/Ihnen" },
  { id: "they_he", label: "Sie/Er" },
  { id: "they_she", label: "Sie/Sie" },
  { id: "other_combinations", label: "Andere Kombinationen" },
];

export const sexualityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "asexual", label: "Asexuell" },
  { id: "bisexual", label: "Bisexuell" },
  { id: "heterosexual", label: "Heterosexuell" },
  { id: "homosexual", label: "Homosexuell" },
  { id: "pansexual", label: "Pansexuell" },
  { id: "queer", label: "Queer" },
  { id: "other_sexual_orientation", label: "Andere sexuelle Orientierung" },
];

export const religionOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "no_religion", label: "Keine Religion" },
  { id: "agnosticism", label: "Agnostizismus" },
  { id: "atheism", label: "Atheismus" },
  { id: "bahaism", label: "Bahá'í" },
  { id: "buddhism", label: "Buddhismus" },
  { id: "christianity", label: "Christentum" },
  { id: "hinduism", label: "Hinduismus" },
  { id: "islam", label: "Islam" },
  { id: "jainism", label: "Jainismus" },
  { id: "judaism", label: "Judentum" },
  { id: "sikhism", label: "Sikhismus" },
  { id: "spirituality", label: "Spiritualität" },
  { id: "other_religious_belief", label: "Andere religiöse Überzeugung" },
];

export const ethnicityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "asian", label: "Asiatisch" },
  { id: "hispanic", label: "Hispanisch" },
  { id: "indigenous", label: "Indigen" },
  { id: "multiracial", label: "Mehrer Ethnien" },
  { id: "middle_eastern", label: "Nahöstlich" },
  { id: "pacific_islander", label: "Pazifischer Inselbewohner" },
  { id: "black", label: "Schwarz" },
  { id: "white", label: "Weiß" },
  { id: "other_ethnicity", label: "Andere Ethnizität" },
];

export const disabilityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "no_disability", label: "Ich habe keine Behinderung" },
  { id: "chronic_illness", label: "Chronische Krankheit" },
  { id: "developmental_disorder", label: "Entwicklungsstörung" },
  { id: "intellectual_disability", label: "Geistige Behinderung" },
  { id: "physical_disability", label: "Körperliche Behinderung" },
  { id: "psychological_impairment", label: "Psychische Beeinträchtigung" },
  { id: "sensory_impairment", label: "Sinnesbehinderung" },
  { id: "invisible_disability", label: "Unsichtbare Behinderung" },
  { id: "other_disability", label: "Andere Behinderung" },
];

export const genderOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "female", label: "Weiblich" },
  { id: "male", label: "Männlich" },
  { id: "diverse", label: "Non-Binär" },
];

export const nationalityOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "argentinian", label: "Argentininasch" },
  { id: "australian", label: "Australisch" },
  { id: "belgian", label: "Belgisch" },
  { id: "chilean", label: "Chilenisch" },
  { id: "czech", label: "Tschechisch" },
  { id: "danish", label: "Dänisch" },
  { id: "egyptian", label: "Ägyptisch" },
  { id: "finnish", label: "Finnisch" },
  { id: "german", label: "Deutsch" },
  { id: "greek", label: "Griechisch" },
  { id: "hungarian", label: "Ungarisch" },
  { id: "irish", label: "Irisch" },
  { id: "korean", label: "Koreanisch" },
  { id: "mexican", label: "Mexikanisch" },
  { id: "new_zealand", label: "Neuseeländisch" },
  { id: "northern_irish", label: "Nordirisch" },
  { id: "norwegian", label: "Norwegisch" },
  { id: "peruvian", label: "Peruanisch" },
  { id: "polish", label: "Polnisch" },
  { id: "portuguese", label: "Portugiesisch" },
  { id: "scottish", label: "Schottisch" },
  { id: "south_african", label: "Südafrikanisch" },
  { id: "swedish", label: "Schwedisch" },
  { id: "swiss", label: "Schweizerisch" },
  { id: "turkish", label: "Türkisch" },
  { id: "us_american", label: "US-amerikanisch" },
  { id: "welsh", label: "Walisisch" },
  { id: "other_nationality", label: "Andere Nationalität" },
];

export const socialBackgroundOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  {
    id: "niedrig",
    label:
      "Niedrig - nie berufstätig gewesen & ungelernte Arbeit, z. B. Kassierer*innen",
    desc: "Niedrig",
  },
  {
    id: "mittel",
    label:
      "Mittel - gelernte Arbeit z. B. Krankenpflege; selbständig & freiberuflich mit niedrigem Einkommen",
    desc: "Mittel",
  },
  {
    id: "gehoben",
    label:
      "Gehoben - angestellt & selbständig mit/ohne akademischen Abschluss und mittlerem Einkommen",
    desc: "Gehoben",
  },
  {
    id: "hoch",
    label:
      "Hoch - angestellt & selbständig mit/ohne akademischen Abschluss und hohem Einkommen",
    desc: "Hoch",
  },
];

export const ageGroupOptions = [
  { id: "not_applicable", label: "Keine Angaben", desc: "Keine Angaben" },
  { id: "0-20", label: "0-20" },
  { id: "21-30", label: "21-30" },
  { id: "31-40", label: "31-40" },
  { id: "41-50", label: "41-50" },
  { id: "51-60", label: "51-60" },
  { id: "60+", label: "60+" },
];

export const levelOptions = [
  { id: "Junior Level", label: "Junior Level" },
  { id: "Head of", label: "Head of" },
  { id: "C-Level", label: "C-Level" },
  { id: "Senior Manager", label: "Senior Manager" },
  { id: "Team Lead", label: "Team Lead" },
  { id: "Student*in", label: "Student*in" },
];

export const statusOptions = [
  { id: "created", label: "Erstellt" },
  { id: "invited", label: "Eingeladen" },
  { id: "active", label: "Aktiv" },
];

export const getOptionById = (option) => {
  return allOptions.find((o) => o.id === option);
};

export const getOptionByDimension = (dimension) => {
  switch (dimension) {
    case "sexuality":
      return sexualityOptions;
    case "gender":
      return genderOptions;
    case "birthdate":
      return ageGroupOptions;
    case "level":
      return levelOptions;
    case "disabilities":
      return disabilityOptions;
    case "ethnicity":
      return ethnicityOptions;
    case "nationality":
      return nationalityOptions;
    case "social_background":
      return socialBackgroundOptions;
    case "religion":
      return religionOptions;

    default:
      return [];
  }
};

export const allOptions = [
  { id: "average", label: "Durchschnitt", desc: "Durchschnitt" },
  ...statusOptions,
  ...pronounOptions,
  ...sexualityOptions,
  ...religionOptions,
  ...levelOptions,
  ...ethnicityOptions,
  ...disabilityOptions,
  ...ageGroupOptions,
  ...genderOptions,
  ...nationalityOptions,
  ...socialBackgroundOptions,
];
