import colors from "../components/common/colors";

const computeDiscrepancyColor = (score) => {
  switch (true) {
    case parseFloat(score) > 1.5:
      return colors.red;
    case parseFloat(score) > 0.5:
      return colors.orange;
    default:
      return colors.green;
  }
};

const computeRatingColor = (score) => {
  if (!score) return colors.white;
  switch (true) {
    case parseFloat(score) > 7.5:
      return colors.green;
    case parseFloat(score) > 5.0:
      return colors.orange;
    default:
      return colors.red;
  }
};

const computeTrendColor = (trend) => {
  switch (true) {
    case parseFloat(trend) < 0:
      return `${colors.red}`;
    case parseFloat(trend) > 0:
      return `${colors.green}`;
    default:
      return `${colors.midnight}`;
  }
};

const computeContrastRatingColor = (score) => {
  if (!score) return colors.white;
  switch (true) {
    case parseFloat(score) > 8.5:
      return colors.green + "88";
    case parseFloat(score) > 7.5:
      return colors.green + "44";
    case parseFloat(score) > 5.0:
      return colors.orange + "44";
    case parseFloat(score) > 3.5:
      return colors.red + "44";
    default:
      return colors.red + "77";
  }
};

export {
  computeDiscrepancyColor,
  computeRatingColor,
  computeTrendColor,
  computeContrastRatingColor,
};
