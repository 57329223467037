import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import colors from "../../common/colors";
import styled from "@emotion/styled";

/*Outer Container*/
export const LoginContainer = styled("div")`
  &.m {
    width: 100%;
  }
  &.s {
    width: 100%;
  }
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

/*Container for buttons and inputfields*/
export const FormContainer = styled("div")`
  &.m {
    max-width: 80%;
  }
  &.s {
    min-width: 80%;
  }
  min-width: 35%;
  overflow: hidden;
`;

/*Container for hintlink*/
export const HintContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

/*Container for button*/
export const ButtonContainer = styled("div")`
  margin-top: 20px;
  margin-bottom: 10px;
`;

/*Attributes for subheader*/
export const SubHeader = styled("div")`
  color: ${colors.darkGrey};
  font-weight: 500;
`;

/*Container for inputfields*/
export const InputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
`;

/*Attributes for button*/
export const StyledButton = styled(Button)`
  width: 100%;
`;

/*Attributes for text (inputfield header)*/
export const StyledTextField = styled(TextField)`
  margin-top: 14px;
`;

/*Attributes for LogoImage*/
export const Logo = styled("img")`
  max-height: 70px;
  max-width: auto;
  &.s {
    position: relative;
    bottom: 12%;
    margin-bottom: 5%;
  }
`;
