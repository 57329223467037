import React, { useEffect, useState } from "react";

import AreaSection from "../common/AreaSection";
import { AreaSectionHeader } from "../common/AreaSectionHeader";
import DiversityOverview from "./DiversityOverview";
import Heading1 from "../common/Heading1";
import LoadingAnimation from "../common/LoadingAnimation";
import MainScoreSL from "./MainScoreSL";
import { ScoreList } from "../scores/ScoreList";
import colors from "../common/colors";
import useAuth from "../../hooks/useAuth";
import useLightsApiPrivate from "../../hooks/useLightsApiPrivate";

const ReportPage = () => {
  const [scores, setScores] = useState(null);
  const [mainScore, setMainScore] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [mainScoreTimeline, setMainScoreTimeline] = useState([]);

  const { auth } = useAuth();
  const showOverview = auth?.id !== "64b15d53cfde297710c4ad85";

  const { getScores, getScoreOverTime, getEmployees, getAccountDimensions } =
    useLightsApiPrivate();

  // TODO check below
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const setScoresLists = async () => {
      const allScores = await getScores();
      const dimensions = await getAccountDimensions();
      const mainScoreTimeline = await getScoreOverTime();
      const employees = await getEmployees();

      setEmployees(employees);
      setMainScoreTimeline(mainScoreTimeline);
      setMainScore(mainScoreTimeline[mainScoreTimeline.length - 1]);
      setDimensions(dimensions);
      setScores(allScores);

      setIsLoading(false);
    };

    if (isLoading) {
      setScoresLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Report</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.MainScoreSLSection}>
        <MainScoreSL
          employees={employees}
          score={mainScore}
          scoreTimeline={mainScoreTimeline}
        ></MainScoreSL>
      </AreaSection>
      {showOverview && (
        <AreaSection content={sectionContent.DiversitySection}>
          <DiversityOverview dimensions={dimensions} />
        </AreaSection>
      )}
      <AreaSection content={sectionContent.OverviewSection}>
        <ScoreList scoreList={scores} />
      </AreaSection>
    </>
  );
};

export default React.memo(ReportPage);

const sectionContent = {
  MainScoreSLSection: {
    id: "main",
    title: "Dein Diverlyze Score",
    infoText:
      "Hier wird widergespiegelt, wie fair sich die Belegschaft behandelt fühlt und welche Pain Points es gibt.",
  },
  FocusSection: {
    id: "focus",
    title: "Dein Fokus",
    infoText:
      "Hier kannst Du zwei Gender Equality Bereiche auswählen, auf die Du Dich konzentrieren möchtest.",
    hoverText: "Diese Fokusbereiche hast Du persönlich festgelegt.",
  },
  OverviewSection: {
    id: "overview",
    title: "Dein Überblick",
    infoText: "Verfolge und vergleiche die Entwicklung aller 12 Bereiche.",
    hoverText:
      "Die 12 Bereiche resultieren aus einer Marktanalyse mit über 100 Arbeitgeber*innen und Arbeitnehmer*innen.",
  },
  DiversitySection: {
    id: "diversity",
    title: "Überblick der Diversitätsdimensionen",
    infoText: "Hier siehst Du wie vielfältig Dein Unternehmen aufgestellt ist.",
  },
  InclusionSection: {
    id: "inclusion",
    title: "Vergleich der Inklusionsgruppen",
    infoText:
      "Hier wird aufgezeigt, welche Gruppen den niedrigsten bzw. höchsten Inklusions-Score aufzeigen.",
    hoverText:
      "Die hier aufgezeigten Gruppen erfordern erhöhten Handlungsbedarf. Alle weiteren Scores findest Du im Contrast Bereich.",
  },
};
