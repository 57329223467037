import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import moment from "moment";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const BirthdayStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [birthdate, setBirthdate] = React.useState(employee.birthdate);

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, birthdate });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, birthdate });
    handleNextStep();
  };

  props = {
    ...props,
    selected: birthdate,
    step,
    headline: "Wann wurdest Du geboren?",
    saveAndNext,
  };

  return (
    <OptionStepContainer props={props}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Geburtsdatum"
          value={birthdate ? moment(birthdate) : null}
          onChange={(e) => {
            e ? setBirthdate(e.toDate()) : setBirthdate(undefined);
          }}
        />
      </LocalizationProvider>
    </OptionStepContainer>
  );
};

export default BirthdayStep;
