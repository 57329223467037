import StatusChip from "../../../common/StatusChip";
import { statusLabels } from "./statusLabels";

export const columns = [
  {
    field: "id",
    headerName: "Id",
    width: 100,
    filterable: false,
  },
  {
    field: "firstName",
    headerName: "Vorname",
    width: 190,
  },
  {
    field: "lastName",
    headerName: "Nachname",
    width: 190,
  },
  {
    field: "mail",
    headerName: "E-Mail",
    width: 290,
  },
  {
    field: "department",
    headerName: "Teams",
    width: 290,
  },
  {
    field: "role",
    headerName: "Rolle",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    valueGetter: (params) => statusLabels[params.value] || "Unbekannt",
    renderCell: (params) => {
      return <StatusChip status={params.row.status} />;
    },
  },
];
