export const sectionContent = {
  survee: {
    id: "dialog",
    title: "Chatte mit den Admins",
    infoText:
      "Hier kannst Du auf Nachrichten der Tool-Admins Deines Unternehmens antworten und anonym über Themen diskutieren, die Dir wichtig sind.",
  },
  admin: {
    id: "dialog",
    title: "Chatte mit der Belegschaft",
    infoText:
      "Hier kannst Du auf Kommentare von Mitarbeitenden antworten und anonym über Themen diskutieren, die der Belegschaft wichtig sind.",
  },
};
