import {
  Container,
  InfoText,
  StyledTableCell,
  StyledTableCellWithoutIcons,
  StyledTeamTableCell,
  TableContainerStyled,
} from "./styles";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";

import ContrastScoreCell from "./ContrastScoreCell";
import LoadingAnimation from "./LoadingAnimation";
import React from "react";
import { getOptionById } from "../../settings/profile/diversityOptions";

const ContrastTable = ({
  scores,
  options,
  selectedDimension,
  selectedArea,
  isTableLoading,
  onLabelClick,
}) => {
  const getOptionLabel = (id) => {
    switch (selectedDimension) {
      case "teamIds":
        return id;
      case "social_background":
        return getOptionById(id)?.desc;
      default:
        return getOptionById(id)?.label;
    }
  };
  const sortOptions = (a, b) => {
    const labelA = getOptionLabel(a);
    const labelB = getOptionLabel(b);
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  };

  const finalOptions = options.sort(sortOptions);

  const uniqueLabels = Array.from(
    new Set(scores.map((score) => score.scoreLabel))
  );

  const sortedLabels = uniqueLabels.sort((a, b) => {
    const scoreA = scores.find((score) => score.scoreLabel === a);
    const scoreB = scores.find((score) => score.scoreLabel === b);

    // Custom area should come last
    if (scoreA?.isCustom && !scoreB?.isCustom) return 1;
    if (!scoreA?.isCustom && scoreB?.isCustom) return -1;

    return a.localeCompare(b);
  });

  const handleCellClick = (label) => {
    if (!selectedArea && onLabelClick) {
      onLabelClick(label);
    }
  };

  if (isTableLoading) {
    return <LoadingAnimation />;
  }

  return (
    <Container>
      {uniqueLabels.length === 0 ? (
        <InfoText> Dieser Bereich hat noch keine Fragen. </InfoText>
      ) : (
        <TableContainerStyled>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTeamTableCell></StyledTeamTableCell>
                {finalOptions.map((optionId) => (
                  <StyledTableCellWithoutIcons key={optionId} bold="true">
                    {getOptionLabel(optionId)}
                  </StyledTableCellWithoutIcons>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedLabels.map((label) => {
                const scoreForLabel = scores.find(
                  (score) => score.scoreLabel === label
                );

                return (
                  <TableRow key={label}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      bold="true"
                      isCustom={scoreForLabel?.isCustom || false}
                      selectedArea={selectedArea}
                      onClick={() => handleCellClick(label)}
                    >
                      {label}
                    </StyledTableCell>
                    {finalOptions.map((option) => {
                      const score = scores.find(
                        (score) =>
                          score.scoreLabel === label && score.option === option
                      );
                      return (
                        <ContrastScoreCell
                          keyName={label + option}
                          score={score}
                        />
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainerStyled>
      )}
    </Container>
  );
};

export default React.memo(ContrastTable);
