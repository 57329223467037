import { InfoText } from "./styles";
const StatusInfoText = ({ status, hasMailAdress }) => {
  switch (status) {
    case "active":
      return (
        <InfoText>
          Das Mitglied hat sich registriert. <br />
          Es nimmt am Pulse Check teil.
        </InfoText>
      );
    case "registered":
      return (
        <InfoText>
          Das Mitglied hat sich in der App registriert. <br /> Es hat jedoch
          noch keine Einwilligung zur Nutzung der Daten gegeben und nimmt noch
          nicht am Pulse Check teil.
        </InfoText>
      );
    case "invited":
      return (
        <InfoText>
          Das Mitglied hat eine Einladung erhalten, sich aber noch nicht
          registriert. <br /> Am Pulse Check nimmt es somit noch nicht teil.
        </InfoText>
      );
    case "created":
      if (hasMailAdress) {
        return (
          <InfoText>
            Das Mitglied hat noch keine Einladung erhalten. <br /> Am Pulse
            Check kann erst teilgenommen werden, wenn ein Account erstellt
            wurde.
          </InfoText>
        );
      } else {
        return (
          <InfoText>
            Das Mitglied hat noch keine Einladung erhalten. <br /> Da das
            Mitglied keine E-Mail-Adresse hat, kann die Einladung nicht über das
            Tool erfolgen.
          </InfoText>
        );
      }
    default:
      return (
        <InfoText>
          Das Mitglied hat sich noch nicht in der App registriert. <br /> Am
          Pulse Check kann erst teilgenommen werden, wenn ein Account erstellt
          wurde.
        </InfoText>
      );
  }
};

export default StatusInfoText;
