import TableCell from "@mui/material/TableCell";
import { TableContainer } from "@mui/material";
import colors from "../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  min-width: 60%;
  max-width: 90%;
  margin-top: -50px;
  overflow-x: auto;
  align-self: flex-start;
  margin-left: 60px;
`;

export const StyledTableCellWithoutIcons = styled(TableCell)`
  font-size: 14px;
  min-width: 80px;
  font-family: Rubik;
  position: sticky;
  overflow: hidden;
  z-index: 3;
  top: 0;
  background-color: white;
  text-align: center;
  font-weight: ${({ bold }) => (bold ? "500" : "normal")};
`;

export const StyledTeamTableCell = styled(TableCell)`
  position: sticky;
  z-index: 9;
  left: 0;
  top: 0;
  background-color: white;
  width: 150px;
`;

export const StyledTableCell = styled(TableCell)`
  font-size: 14px;
  max-width: ${({ selectedArea }) => (selectedArea ? "200px" : "125px")};
  min-width: ${({ selectedArea }) => (selectedArea ? "200px" : "125px")};
  width: ${({ selectedArea }) => (selectedArea ? "200px" : "125px")};
  position: sticky;
  height: ${({ selectedArea }) => (selectedArea ? "auto" : "30px")};
  left: 0;
  background-color: ${({ isCustom }) =>
    isCustom ? colors.lavenderLight : "white"};
  z-index: 4;
  font-family: Rubik;
  text-align: ${({ selectedArea }) => (selectedArea ? "left" : "center")};
  cursor: ${({ selectedArea }) => (selectedArea ? "default" : "pointer")};

  &:hover {
    font-weight: ${({ selectedArea }) => (selectedArea ? "0" : "500")};
  }
`;

export const InfoText = styled("div")`
  font-size: 20px;
  font-weight: 500;
  color: ${colors.darkGrey};
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  margin-left: 400px;
`;

export const TableContainerStyled = styled(TableContainer)`
  max-height: 650px;
  overflow-x: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.grey};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.lightGrey};
  }
`;
