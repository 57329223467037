import { Alert } from "@mui/material";
import { AlertContainer } from "./styles";
import { DividerAction } from "./styles";
import React from "react";

const InvitationAlert = ({ showAlert }) => {
  return (
    <AlertContainer>
      {showAlert && (
        <>
          <Alert severity="warning">
            Noch nicht alle Mitglieder haben sich im Tool registriert.
          </Alert>
          <DividerAction orientation="vertical" flexItem />
        </>
      )}
    </AlertContainer>
  );
};

export default InvitationAlert;
