import React, { useEffect, useState } from "react";

import ActionArea from "./ActionArea";
import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import EmployeesTable from "./EmployeesTable";
import Heading1 from "../../common/Heading1";
import LoadingAnimation from "../../common/LoadingAnimation";
import colors from "../../common/colors";
import compare from "../../../utils/sort";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";

const UsersPage = () => {
  const { getEmployees } = useLightsApiPrivate();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (loading) {
      getEmployees().then((data) => {
        const preparedEmployees = prepareEmployeeData(data);
        setEmployees(preparedEmployees);

        setLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareEmployeeData = (e) => {
    const newList = e.map((employee) => {
      return {
        id: employee._id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        mail: employee.mail,
        role: employee.role,
        department: employee.department,
        activity: employee.isActive,
        status: employee.status,
      };
    });
    return newList.sort(compare);
  };

  const updateEmployeeList = async () => {
    const data = await getEmployees();
    const preparedEmployees = prepareEmployeeData(data);
    setEmployees(preparedEmployees);
  };

  const filteredEmployees = employees.filter((emp) =>
    `${emp.firstName} ${emp.lastName} ${emp.mail} `
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Mitglieder</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.TeamsSection}>
        <ActionArea
          employees={employees}
          updateEmployeeList={updateEmployeeList}
          setEmployees={setEmployees}
          setSearchQuery={setSearchQuery}
        />
        <EmployeesTable employees={filteredEmployees} />
      </AreaSection>
    </>
  );
};

export default UsersPage;

const sectionContent = {
  TeamsSection: {
    id: "teams",
    title: "Deine Mitglieder",
    infoText:
      "Hier kannst Du alle Teammitglieder und deren Rechte im Detail anschauen und verwalten.",
    hoverText:
      "Die Teammitglieder wurden beim Onboarding durch das Hochladen der CSV importiert. Du kannst sie hier bearbeiten, löschen oder neue hinzufügen.",
  },
};
