import { Button, ButtonText } from "./styles";

import React from "react";
import colors from "../../colors";
import usePortalSize from "../../../../hooks/usePortalSize";

const NumberButton = ({ rating, active = false, onClick }) => {
  const windowSize = usePortalSize();

  const color = active ? colors.green : "#000";
  return (
    <Button
      color={color}
      onClick={() => onClick(rating)}
      className={windowSize}
    >
      <ButtonText color={color}>{rating}</ButtonText>
    </Button>
  );
};

export default NumberButton;
