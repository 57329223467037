import { Checkbox, FormControlLabel } from "@mui/material";

import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";

const ParentalLeaveInput = ({ props }) => {
  const { employee, setEmployee } = props;
  return (
    <FieldContainer minWidth="100%">
      <FormControlLabel
        control={
          <Checkbox
            checked={employee.hadParentingTime}
            onChange={({ target }) => {
              setEmployee({
                ...employee,
                hadParentingTime: target.checked,
              });
            }}
          />
        }
        label="Ich war in diesem Unternehmen in Elternzeit"
      />
    </FieldContainer>
  );
};

export default ParentalLeaveInput;
