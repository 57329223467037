import { Button } from "@mui/material";
import Heading1 from "../../../common/Heading1";
import SurveeOnboardingContainer from "../SurveeOnboardingContainer";
import Text from "../../../common/Text";
import { TextContainer } from "./styles";
import usePortalSize from "../../../../hooks/usePortalSize";

const SurveeWelcomeStep = ({ props }) => {
  const { handleNextStep } = props;

  const windowSize = usePortalSize();

  return (
    <SurveeOnboardingContainer>
      <TextContainer className={windowSize}>
        <Heading1>Willkommen bei Diverlyze!</Heading1>
        <Text className={windowSize} align={"justify"}>
          Wir wollen in Deinem Unternehmen ein inklusives und vielfältiges
          Arbeitsumfeld schaffen, in dem sich alle wertgeschätzt, respektiert
          und unterstützt fühlen. Um dieses Ziel zu erreichen, brauchen wir
          Deine Hilfe.
        </Text>
        <Text className={windowSize} align={"justify"}>
          Im Folgenden wirst Du gebeten, diverse Angaben zu machen.
        </Text>
        <Text className={windowSize} align={"justify"}>
          Diese ermöglichen es uns die vielfältigen Hintergründe Deines
          Unternehmens besser zu verstehen und mögliche Ungleichheiten zu
          erkennen. Dieses Wissen hilft uns, bei der Entwicklung von
          Richtlinien, Programmen und Initiativen, damit sich alle willkommen
          und vertreten fühlen.
        </Text>
        <Text className={windowSize} align={"justify"}>
          Alle Deine Informationen werden <b>anonymisiert</b>. D.h. niemand aus
          eurem Unternehmen kann sie einsehen. Natürlich sind alle Angaben
          freiwillig, aber wir bitten Dich, keinen Schritt auszulassen.
        </Text>
      </TextContainer>
      <Button
        variant="confirm"
        onClick={() => {
          handleNextStep();
        }}
      >
        Start Onboarding
      </Button>
    </SurveeOnboardingContainer>
  );
};

export default SurveeWelcomeStep;
