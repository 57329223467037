import { ActionAlertDropdown, ActionContainer, Container } from "./styles";
import React, { useEffect, useState } from "react";

import CreationModal from "./modals/CreationModal";
import DropdownAction from "./DropdownAction";
import InvitationAlert from "./InvitationAlert";
import InvitationModal from "./modals/InvitationModal";
import ReminderModal from "./ReminderModal";
import SearchUser from "./SearchUser";

const ActionArea = ({
  updateEmployeeList,
  employees,
  setEmployees,
  setSearchQuery,
}) => {
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState(false);
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);

  const [newEmps, setNewEmps] = useState(false);
  const [invitedEmps, setInvitedEmps] = useState([]);

  const hasUnregisteredEmps = newEmps.length > 0 || invitedEmps.length > 0;

  useEffect(() => {
    const empsWithMail = employees.filter((emp) => emp.mail);
    const newEmps = empsWithMail.filter((emp) => emp.status === "created");
    const invitedEmps = empsWithMail.filter((emp) => emp.status === "invited");

    setNewEmps(newEmps);
    setInvitedEmps(invitedEmps);
  }, [employees]);

  return (
    <Container>
      <InvitationModal
        amountNewEmps={newEmps.length}
        isModalOpen={isInvitationModalOpen}
        closeModal={() => setIsInvitationModalOpen(false)}
        setNewEmps={setNewEmps}
        employees={employees}
        setEmployees={setEmployees}
      />
      <ReminderModal
        amountInvitedEmps={invitedEmps.length}
        isModalOpen={isReminderModalOpen}
        closeModal={() => setIsReminderModalOpen(false)}
        setHasNewEmps={setInvitedEmps}
        employees={employees}
        setEmployees={setEmployees}
      />
      <CreationModal
        isModalOpen={isCreateNewModalOpen}
        closeModal={() => setIsCreateNewModalOpen(false)}
        updateEmployeeList={updateEmployeeList}
      />
      <ActionContainer>
        <SearchUser setSearchQuery={setSearchQuery} />
        <ActionAlertDropdown>
          <InvitationAlert showAlert={hasUnregisteredEmps} />
          <DropdownAction
            onOpenCreateModal={() => setIsCreateNewModalOpen(true)}
            onOpenInviteModal={() => setIsInvitationModalOpen(true)}
            onOpenReminderModal={() => setIsReminderModalOpen(true)}
            isInviteActive={newEmps.length > 0}
            isRemindActive={invitedEmps.length > 0}
          />
        </ActionAlertDropdown>
      </ActionContainer>
    </Container>
  );
};

export default ActionArea;
