import React from "react";
import { StyledDataGrid } from "./styles";
import { cellStyle } from "./styles";
import { columns } from "./columns";
import { localizedTextsMap } from "../../../../localization/dataGrid";
import { useNavigate } from "react-router-dom";

const EmployeesTable = ({ employees }) => {
  const navigate = useNavigate();

  const handleRowClick = ({ row }) => {
    navigate(`/settings/users/${row.id}/profile`);
  };

  return (
    <>
      <StyledDataGrid
        disableColumnSelector
        stickyHeader
        onRowClick={handleRowClick}
        sx={cellStyle}
        localeText={localizedTextsMap}
        columns={columns}
        rows={employees}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
      />
    </>
  );
};

export default EmployeesTable;
