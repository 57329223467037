import { CustomButton, GrowStyled, IconButton } from "./styles";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React from "react";

export default function DropdownAction({
  onOpenCreateModal,
  onOpenInviteModal,
  onOpenReminderModal,
  isInviteActive,
  isRemindActive,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const options = [
    { label: "Mitglied erstellen", disabled: false },
    { label: "Erstellte Mitglied einladen", disabled: !isInviteActive },
    { label: "Erinnerung senden", disabled: !isRemindActive },
  ];

  const handleClick = (index) => {
    if (index === 0) {
      onOpenCreateModal();
    } else if (index === 1) {
      onOpenInviteModal();
    } else if (index === 2) {
      onOpenReminderModal();
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="Action options button group"
      >
        <CustomButton onClick={handleToggle}>Aktionen</CustomButton>
        <IconButton aria-haspopup="menu" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </IconButton>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <GrowStyled {...TransitionProps} placement={placement}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      disabled={option.disabled}
                      onClick={() => handleClick(index)}
                      sx={{
                        fontFamily: "Rubik",
                        width: "180px",
                        paddingLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </GrowStyled>
        )}
      </Popper>
    </Box>
  );
}
